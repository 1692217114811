import { ReactNode } from "react";
import classNames from "classnames";

type CardColorScheme = "default" | "white" | "ppf";

interface CardProps {
  children: ReactNode;
  colorScheme?: CardColorScheme;
  header?: ReactNode;
  isChildrenWithPadding?: boolean;
  id?: string;
  onClick?: () => void;
}

export const Card = ({
  children,
  header,
  id,
  colorScheme = "default",
  isChildrenWithPadding,
  onClick,
}: CardProps) => {
  return (
    <div
      id={id}
      className={classNames(
        "flex overflow-hidden flex-col w-full h-full bg-[#edf2f7] rounded-xl shadow-lg",
        {
          "cursor-pointer": !!onClick,
        }
      )}
      onClick={onClick}
    >
      {header && (
        <div
          className={classNames(
            "p-4 text-2xl font-semibold",
            colorScheme === "default" && "bg-[#edf2f7] text-[#002c5a]",
            colorScheme === "white" && "bg-white text-[#002c5a]",
            colorScheme === "ppf" && "bg-[#002c5a] text-white"
          )}
        >
          {header}
        </div>
      )}
      <div
        className={classNames(
          "flex overflow-hidden flex-col w-full h-full bg-white",
          colorScheme === "ppf" && "bg-[#002c5a]",
          {
            "px-4 pb-4": isChildrenWithPadding,
          }
        )}
      >
        {children}
      </div>
    </div>
  );
};